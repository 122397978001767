<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
          placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"            
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
            <v-btn block color="amber darken-4" dark @click="dialogSave()">
                <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
            </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" :search="search" dense locale="pt-BR">
        <template v-slot:item.action="{item}">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>

      <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200px">
        <template>
          <v-row>
            <v-col md="5" sm="12" cols="12">
              <b> Nome Fantasia </b>
              <v-text-field placeholder="Nome" v-model="object.name" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="5" sm="12" cols="12">
              <b> Razão Social </b>
              <v-text-field placeholder="Razão Social" v-model="object.socialName" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="2" sm="12" cols="12">
              <b> CNPJ </b>
              <v-text-field placeholder="CNPJ" v-model="object.document" v-mask="'##.###.###/####-##'" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3" sm="12" cols="12">
              <b> CEP </b>
              <v-text-field placeholder="CEP" v-model="object.cep" @keyup="getCep()" v-mask="'#####-###'" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="8" sm="12" cols="12">
              <b> Endereço </b>
              <v-text-field placeholder="Rua do Limoeiro" v-model="object.address" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="1" sm="12" cols="12">
              <b> Número </b>
              <v-text-field placeholder="13" v-model="object.number" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" sm="12" cols="12">
              <b> Bairro </b>
              <v-text-field placeholder="Bairro" v-model="object.neighborhood" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <b> Cidade </b>
              <v-text-field placeholder="Curitiba" v-model="object.city" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="2" sm="12" cols="12">
              <b> Estado </b>
              <v-text-field placeholder="Paraná" v-model="object.state" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3" sm="12" cols="12">
              <b> Responsável </b>
              <v-text-field placeholder="Responsável" v-model="object.actor" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <b> Telefone </b>
              <v-text-field
                placeholder="(##) #####-####"
                v-model="object.phone"
                v-mask="'(##)#####-####'"
                dense
                hide-details
                outlined
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <b> Email </b>
              <v-text-field placeholder="nome@provedor.com" v-model="object.email" dense hide-details outlined color="primary"></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions="">
          <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
            <!-- <v-icon small class="mr-2">mdi-plus</v-icon> -->
            <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
            <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
          </v-btn>
        </template>
      </TheDialog>
    </v-card>
    
  </v-container>
</template>

<script>
import axios from 'axios'
import {baseApiUrl, showError} from '@/global'
import {mask} from 'vue-the-mask'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Dados',
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },
  directives: {mask},
  data: () => ({
    baseRoute: 'company',
    title: 'Empresas',
    pageIcon: 'mdi-office-building-outline',
    dialog: false,
    search: null,
    object: {},
    header: [
      {text: 'Codigo', value: 'id', width: '10%'},
      {text: 'Nome', value: 'name'},
      {text: 'Razão Social', value: 'socialName'},
      {text: 'CNPJ', value: 'document'},
      {text: 'Ações', value: 'action', width: '10%'},
    ],
    objects: [],
    error: false,
    edit: false,
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
        })
        .catch(showError)
    },
    getCep() {
      console.error("teste")
      if (this.object.cep.length < 8) {
        return
      }
      const cep = this.object.cep.replace('-', '')
      const url = `${baseApiUrl}/cep/${cep}`
      this.loading = true

      axios
        .get(url)
        .then((res) => {
          this.dialog = false
          const data = res.data
          this.object.address = data.logradouro
          this.object.neighborhood = data.bairro
          this.object.city = data.localidade
          this.object.state = data.uf
          this.loading = false
          this.dialog = true
        })
        .catch(showError)
    },
    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.object = item
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id

      delete objects.description
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>